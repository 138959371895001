import React from "react";

const AboutMe = () => {
  return (
    <div>
      <h1 className="text-center font-bold text-3xl mb-8" id="abt-me">
        About Me
      </h1>
      <p className="text-lg text-green-800 text-center" id="abt-me-subtext">
        Hey there 👋🏾, I am Kayode Babalola, a Front-end Developer, Project
        Manager and Creative Writer. I consider myself a creative first and this
        has helped me in extending and inter-relating my abilities in all my
        creative endavours. I have 3+ years experience as a Front-end Developer
        and Project Manager and 4+ years experience as a Creative Writer.
      </p>
    </div>
  );
};

export default AboutMe;
